import * as React from "react"
import { Link } from "gatsby"
import Navbar from './components/Navbar'
import './scss/_global.scss'
import './scss/404.scss'

// markup
const NotFoundPage = () => {
  return (
    <main>
      <title>virtual WestJet | 404</title>
      <Navbar />
      <div className="error">
        <div className="error__text">
          <h1>That Flight Already Departed</h1>
          <Link to="/" className="nostyle"><h3>Back Home</h3></Link>
        </div>
        <div className="error__code">
          <h1>404</h1>
        </div>
      </div>
    </main>
  )
}

export default NotFoundPage
